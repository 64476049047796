<template>
  <main-layout>
    <div class="calendarPage__titleContainer">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="calendarPage__title">
        {{ companyInformation.name }}
      </h1>
    </div>
    <div class="companyPage__steps mb-20">
      <div
        @click="selectCategory('overview')"
        class="companyPage__step"
        :class="{ 'companyPage__step-active': show.overview }"
      >
        {{ $t("accountOverviewPageOverview") }}
      </div>
      <div
        @click="selectCategory('billing')"
        class="companyPage__step"
        :class="{ 'companyPage__step-active': show.billing }"
      >
        {{ $t("billingInformation") }}
      </div>
      <div
        @click="selectCategory('platform')"
        class="companyPage__step"
        :class="{ 'companyPage__step-active': show.platform }"
      >
        {{ $t("platform") }}
      </div>
      <div class="companyPage__step opacity-25">
        {{ $t("codeCredit") }}
      </div>
    </div>
    <div v-if="show.overview" class="companyPage__logoInformation">
      <div class="companyPage__logo">
        <company-logo
          v-if="!isLoading"
          @click="showLogoPopup()"
          size="300"
          :company-page="true"
          :company_slug="companyInformation.code"
          :image-name="companyInformation.logo"
        />
      </div>
      <div class="companyPage__idWrapper">
        <div class="companyPage__idTitle">{{ $t("companyId") }}:</div>
        <div class="companyPage__id">{{ companyInformation.code }}</div>
        <div class="companyPage__id">
          {{ $t("filterByLastSeen") }}:
          <data-last-seen :data="companyInformation.user.last_seen" />
        </div>
      </div>
    </div>
    <div v-if="show.overview" class="companyAddPage__companyInformation">
      <h5 class="companyAddPage__blockTitle">{{ $t("companyInformation") }}</h5>
      <div class="row">
        <div class="col-lg-6 pr-25">
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.name"
            :error="v$.companyInformation.name.$error"
            :error-message="v$.companyInformation.name.$errors"
          >
            {{ $t("companyName") }}*
          </base-input>
          <base-input
            class="w-100 companyAddPage__input"
            type="number"
            v-model="companyInformation.number_of_employees"
          >
            {{ $t("numberOfEmployees") }}*
          </base-input>
          <div class="expertAddPage__input">
            <span
              :class="{ 'baseInput__area-focus': companyInformation.activity }"
              class="baseInput__label"
            >
              {{ $t("fieldOfActivity") }}*
            </span>
            <vue-multiselect
              class="accountOverviewMultiselect"
              :options="companyActivitiesOptions"
              v-model="companyInformation.activity"
              placeholder=""
              :show-labels="false"
              openDirection="bottom"
              label="name"
              track-by="name"
            />
          </div>
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.code"
            :maxlength="15"
            :error="v$.companyInformation.code.$error"
            :error-message="v$.companyInformation.code.$errors"
          >
            {{ $t("companyReference") }}*
          </base-input>
        </div>
        <div class="col-lg-6 pl-25">
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.vat_number"
            >№
            {{ $t("vat") }}
          </base-input>
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.country"
          >
            {{ $t("country") }}
          </base-input>
          <div class="d-flex justify-content-between">
            <base-input
              class="expertAddPage__input expertAddPage__input-small"
              v-model="companyInformation.city"
            >
              {{ $t("city") }}
            </base-input>
            <base-input
              class="expertAddPage__input expertAddPage__input-small"
              v-model="companyInformation.postal_code"
            >
              {{ $t("postalCode") }}
            </base-input>
          </div>
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.address"
          >
            {{ $t("accountOverviewPageAddress") }}
          </base-input>
        </div>
      </div>
      <h5 class="companyAddPage__blockTitle">{{ $t("contactInformation") }}</h5>
      <div class="row">
        <div class="col-lg-6 pr-25">
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.contact.first_name"
            :error="v$.companyInformation.contact.first_name.$error"
            :error-message="v$.companyInformation.contact.first_name.$errors"
          >
            {{ $t("firstName") }}*
          </base-input>
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.user.email"
            :error="v$.companyInformation.user.email.$error"
            :error-message="v$.companyInformation.user.email.$errors"
          >
            {{ $t("accountOverviewPageEmail") }}*
          </base-input>
          <div class="row">
            <div class="col-lg-12 col-xxl-6">
              <div class="expertAddPage__input">
                <span
                  :class="{
                    'baseInput__area-focus': companyInformation.contact.gender,
                  }"
                  class="baseInput__label"
                >
                  {{ $t("accountOverviewPageGender") }}
                </span>
                <vue-multiselect
                  :options="genderOptions"
                  class="accountOverviewMultiselect"
                  v-model="companyInformation.contact.gender"
                  placeholder=""
                  :show-labels="false"
                  openDirection="bottom"
                  label="name"
                  track-by="name"
                />
              </div>
            </div>
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.contact.office_number"
              >
                {{ $t("accountOverviewPageOffice") }}
              </base-input>
            </div>
          </div>
          <div class="expertAddPage__input">
            <span
              :class="{ 'baseInput__area-focus': companyInformation.language }"
              class="baseInput__label"
            >
              {{ $t("accountOverviewPageLanguage") }}*
            </span>
            <vue-multiselect
              :options="languageOptions"
              class="accountOverviewMultiselect"
              v-model="companyInformation.language"
              placeholder=""
              :show-labels="false"
              openDirection="bottom"
              label="name"
              track-by="name"
            />
          </div>
        </div>
        <div class="col-lg-6 pl-25">
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.contact.last_name"
            :error="v$.companyInformation.contact.last_name.$error"
            :error-message="v$.companyInformation.contact.last_name.$errors"
          >
            {{ $t("lastName") }}*
          </base-input>
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.contact.job_title"
          >
            {{ $t("jobTitle") }}
          </base-input>
          <div class="row">
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.contact.mobile_number"
              >
                {{ $t("accountOverviewPageMobile") }}
              </base-input>
            </div>
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.contact.phone_number"
              >
                {{ $t("accountOverviewPagePhone") }}
              </base-input>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div
          v-show="!haveChanges"
          @click="getCompanyInformation()"
          class="servicesAddPage__cancel"
        >
          {{ $t("discardChanges") }}
        </div>
        <base-button @click="saveChanges()" class="companyAddPage__saveButton"
          >{{ $t("accountOverviewPageSave") }}
        </base-button>
      </div>
    </div>
    <div v-if="show.billing" class="companyAddPage__companyInformation">
      <h5 class="companyAddPage__blockTitle">{{ $t("billingInformation") }}</h5>
      <div class="row">
        <div class="col-lg-6 pr-25">
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.billing.name"
            :error="v$.companyInformation.billing.name.$error"
            :error-message="v$.companyInformation.billing.name.$errors"
          >
            {{ $t("companyName") }}*
          </base-input>
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.billing.country"
          >
            {{ $t("country") }}
          </base-input>
          <div class="row">
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.billing.city"
              >
                {{ $t("city") }}
              </base-input>
            </div>
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.billing.postal_code"
              >
                {{ $t("postalCode") }}
              </base-input>
            </div>
          </div>
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.billing.address"
          >
            {{ $t("accountOverviewPageAddress") }}
          </base-input>
          <div class="row">
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.billing.vat_number"
              >
                № {{ $t("vat") }}
              </base-input>
            </div>
          </div>
        </div>
        <div class="col-lg-6 pl-25">
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.billing.email"
            :error="v$.companyInformation.billing.email.$error"
            :error-message="v$.companyInformation.billing.email.$errors"
          >
            {{ $t("accountOverviewPageEmail") }}*
          </base-input>
          <div class="row">
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.billing.first_name"
                :error="v$.companyInformation.billing.first_name.$error"
                :error-message="
                  v$.companyInformation.billing.first_name.$errors
                "
              >
                {{ $t("firstName") }}*
              </base-input>
            </div>
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.billing.last_name"
                :error="v$.companyInformation.billing.last_name.$error"
                :error-message="v$.companyInformation.billing.last_name.$errors"
              >
                {{ $t("lastName") }}*
              </base-input>
            </div>
          </div>
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.contact.job_title"
          >
            {{ $t("jobTitle") }}
          </base-input>
          <div class="row">
            <div class="col-lg-12 col-xxl-6">
              <div class="expertAddPage__input">
                <span
                  :class="{
                    'baseInput__area-focus': companyInformation.billing.gender,
                  }"
                  class="baseInput__label"
                >
                  {{ $t("accountOverviewPageGender") }}
                </span>
                <vue-multiselect
                  :options="genderOptions"
                  class="accountOverviewMultiselect"
                  v-model="companyInformation.billing.gender"
                  placeholder=""
                  :show-labels="false"
                  openDirection="bottom"
                  label="name"
                  track-by="name"
                />
              </div>
            </div>
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.billing.office_number"
              >
                {{ $t("accountOverviewPageOffice") }}
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.billing.mobile_number"
              >
                {{ $t("accountOverviewPageMobile") }}
              </base-input>
            </div>
            <div class="col-lg-12 col-xxl-6">
              <base-input
                class="w-100 companyAddPage__input"
                v-model="companyInformation.billing.phone_number"
              >
                {{ $t("accountOverviewPagePhone") }}
              </base-input>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div
          v-show="!haveChanges"
          @click="getCompanyInformation()"
          class="servicesAddPage__cancel"
        >
          {{ $t("discardChanges") }}
        </div>
        <base-button @click="saveChanges()" class="companyAddPage__saveButton"
          >{{ $t("accountOverviewPageSave") }}
        </base-button>
      </div>
    </div>
    <div v-if="show.platform" class="companyAddPage__companyInformation">
      <h5 class="companyAddPage__blockTitle">
        {{ $t("platformSubscription") }}
      </h5>
      <div class="row">
        <div class="col-lg-4 col-xxl-6">
          <base-input
            class="w-100 companyAddPage__input"
            mask="#*.## €"
            v-model="companyInformation.subscription.price"
            :error="v$.companyInformation.subscription.price.$error"
            :error-message="v$.companyInformation.subscription.price.$errors"
          >
            {{ $t("subscriptionPrice") }}, €*
          </base-input>
        </div>
        <div class="col-lg-4 col-xxl-3">
          <base-input
            class="w-100 companyAddPage__input companyAddPage__input-nowarp"
            :autocomplete="true"
            :read-only="true"
            v-model="companyInformation.subscription.valid_until"
          >
            {{ $t("nextBillingDate") }}
          </base-input>
        </div>
        <div class="col-lg-4 col-xxl-3">
          <base-input
            mask="######"
            class="w-100 companyAddPage__input"
            v-model="companyInformation.subscription.contract_code"
          >
            {{ $t("contract") }} №*
          </base-input>
        </div>
      </div>
      <h5 class="companyAddPage__blockTitle">{{ $t("platformParameter") }}</h5>
      <div class="row">
        <div class="col-lg-4 col-xxl-6">
          <base-input
            class="w-100 companyAddPage__input"
            v-model="companyInformation.parameter.domain"
            :error="v$.companyInformation.parameter.domain.$error"
            :error-message="v$.companyInformation.parameter.domain.$errors"
          >
            {{ $t("DomainEnterprise") }}
          </base-input>
        </div>
        <div class="col-lg-4 col-xxl-3">
          <base-input
            class="w-100 companyAddPage__input"
            mask="## %"
            v-model="companyInformation.parameter.payment_percentage"
            :error="v$.companyInformation.parameter.payment_percentage.$error"
            :error-message="
              v$.companyInformation.parameter.payment_percentage.$errors
            "
          >
            {{ $t("companyParticipation") }}, %*
          </base-input>
        </div>
        <div class="col-lg-4 col-xxl-3">
          <base-input
            class="w-100 companyAddPage__input"
            mask="#*.## €"
            v-model="companyInformation.parameter.payment_limit"
            :error="v$.companyInformation.parameter.payment_limit.$error"
            :error-message="
              v$.companyInformation.parameter.payment_limit.$errors
            "
          >
            {{ $t("companyParticipation") }}, €*
          </base-input>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div
          v-show="!haveChanges"
          @click="getCompanyInformation()"
          class="servicesAddPage__cancel"
        >
          {{ $t("discardChanges") }}
        </div>
        <base-button @click="saveChanges()" class="companyAddPage__saveButton"
          >{{ $t("accountOverviewPageSave") }}
        </base-button>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import VueMultiselect from "vue-multiselect";
import DataLastSeen from "@/components/components-helpers/DataLastSeen";
import { mapGetters } from "vuex";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import CompanyLogo from "@/components/UI/images/CompanyLogo";
import BaseInput from "@/components/UI/inputs/BaseInput";
import { format } from "date-fns";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { clearString } from "@/helpers/clearStringToNumber";
import { email, required } from "@vuelidate/validators";

export default {
  name: "CompanyPage",
  components: {
    BaseInput,
    CompanyLogo,
    HorizontalArrow,
    DataLastSeen,
    BaseButton,
    MainLayout,
    VueMultiselect,
  },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      isLoading: false,
      haveChanges: null,
      show: {
        overview: true,
        billing: false,
        platform: false,
        code: false,
      },
      informationBackups: {},
      thumbnail: "",
      oldImage: "",
      companyActivitiesOptions: [],
      jobTitleOptions: [],
      genderOptions: [],
      languageOptions: [],
      paymentLimit: "",
      paymentPercentage: "",
      companyInformation: {
        name: "",
        number_of_employees: "",
        activity: "",
        code: "",
        vat_number: "",
        country: "",
        city: "",
        postal_code: "",
        address: "",
        job_title: "",
        language: "",
        user: {
          language_id: "",
          last_seen: "",
          email: "",
          password: "",
        },
        subscription: {
          contract_code: "",
          price: "",
          valid_from: "",
          valid_until: "",
        },
        parameter: {
          domain: "",
          money_spent: "",
          payment_limit: "",
          payment_percentage: "",
        },
        contact: {
          first_name: "",
          last_name: "",
          email: "",
          mobile_number: "",
          office_number: "",
          phone_number: "",
          gender: {
            id: "",
            name: "",
          },
        },
        billing: {
          name: "",
          address: "",
          country: "",
          city: "",
          postal_code: "",
          vat_number: "",
          email: "",
          first_name: "",
          last_name: "",
          job_title: "",
          gender: "",
          office_number: "",
          mobile_number: "",
          phone_number: "",
        },
      },
    };
  },
  validations() {
    return {
      companyInformation: {
        name: { required },
        code: { required },
        user: {
          email: { required, email },
        },
        billing: {
          name: { required },
          email: { required, email },
          first_name: { required },
          last_name: { required },
        },
        subscription: {
          price: { required },
        },
        parameter: {
          payment_percentage: { required },
          payment_limit: { required },
          domain: { required },
        },
        contact: {
          first_name: { required },
          last_name: { required },
        },
      },
    };
  },
  mounted() {
    this.getOptions();
    this.getCompanyInformation();
    this.$store.commit("SET_LOADED_STATE", false);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.haveChanges) {
      let changesPopup = {
        show: true,
        to: to.path,
        save: null,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    } else {
      return next();
    }
  },
  methods: {
    getOptions() {
      this.$http.get("company-activities").then(({ data }) => {
        data.body.forEach((element) => {
          this.companyActivitiesOptions.push({
            name: element.translations[0].name,
            id: element.id,
          });
        });
      });
      this.$http.get("genders").then(({ data }) => {
        this.genderOptions = data.body;
      });
      this.$http.get("platform-languages").then(({ data }) => {
        this.languageOptions = data.body;
      });
      this.$http.get("employees-positions").then(({ data }) => {
        this.jobTitleOptions = data.body;
      });
    },
    async getCompanyInformation() {
      let companyByCode = await this.$http
        .get("companies/code/" + this.$route.params.company)
        .then(({ data }) => {
          return data.body;
        });
      let companyInformation = await this.$http
        .get(`companies/${companyByCode.user_id}`)
        .then(({ data }) => {
          return data.body;
        });
      let info = {
        is_blocked: companyInformation.user.is_blocked,
        user_id: companyInformation.user_id,
        profile_id: companyInformation.user.profile_id,
        role_id: 3,
        email: companyInformation.user.email,
      };
      this.$store.commit("SET_EDIT_USER_INFORMATION", info);
      this.$store.commit("SET_THUMBNAIL", companyInformation.logo);
      companyInformation.subscription.valid_until = format(
        new Date(companyInformation.subscription.valid_until),
        "dd MMM yyyy"
      );
      companyInformation.activity = this.companyActivitiesOptions.filter(
        (element) => element.id === companyInformation.activity_id
      );
      companyInformation.language = this.languageOptions.filter(
        (element) => element.id === companyByCode.user.language_id
      )[0];
      this.informationBackups = JSON.parse(JSON.stringify(companyInformation));
      this.companyInformation = { ...companyInformation };
    },
    saveChanges() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      this.$store.commit("SET_LOADED_STATE", true);
      this.$http
        .patch(`companies/${this.companyInformation.user_id}`, {
          user_id: this.companyInformation.user_id,
          name: this.companyInformation.name,
          code: this.companyInformation.code,
          vat_number: this.companyInformation.vat_number,
          activity_id: this.companyInformation.activity[0].id,
          country: this.companyInformation.country,
          city: this.companyInformation.city,
          postal_code: this.companyInformation.postal_code,
          address: this.companyInformation.address,
          number_of_employees: Number(
            this.companyInformation.number_of_employees
          ),
          logo: this.companyThumbnail,
          profile: {
            language_id: this.companyInformation.language
              ? this.companyInformation.language.id
              : null,
          },
          user: {
            email: this.companyInformation.user.email,
          },
          contact: {
            gender_id: this.companyInformation.contact.gender
              ? this.companyInformation.contact.gender.id
              : null,
            first_name: this.companyInformation.contact.first_name,
            last_name: this.companyInformation.contact.last_name,
            job_title: this.companyInformation.contact.job_title,
            mobile_number: this.companyInformation.contact.mobile_number,
            phone_number: this.companyInformation.contact.phone_number,
            office_number: this.companyInformation.contact.office_number,
          },
          billing: {
            name: this.companyInformation.billing.name,
            country: this.companyInformation.billing.country,
            city: this.companyInformation.billing.city,
            postal_code: this.companyInformation.billing.postal_code,
            address: this.companyInformation.billing.address,
            vat_number: this.companyInformation.billing.vat_number,
            gender_id: this.companyInformation.billing.gender
              ? this.companyInformation.billing.gender.id
              : null,
            first_name: this.companyInformation.billing.first_name,
            last_name: this.companyInformation.billing.last_name,
            job_title: this.companyInformation.billing.job_title,
            mobile_number: this.companyInformation.billing.mobile_number,
            phone_number: this.companyInformation.billing.phone_number,
            office_number: this.companyInformation.billing.office_number,
          },
          subscription: {
            contract_code: this.companyInformation.subscription.contract_code,
            valid_from: this.companyInformation.subscription.valid_from,
            valid_until: this.companyInformation.subscription.valid_until,
            price: clearString(this.companyInformation.subscription.price),
          },
          parameter: {
            domain: this.companyInformation.parameter.domain,
            payment_percentage: clearString(
              this.companyInformation.parameter.payment_percentage
            ),
            payment_limit: clearString(
              this.companyInformation.parameter.payment_limit
            ),
          },
        })
        .then(() => {
          this.toast.success(this.$t("successfulSaveData"));
          this.$store.commit("SET_LOADED_STATE", false);
          this.$store.commit("SET_COMPANIES_ITEMS", null);
          this.haveChanges = true;
          this.$router.push("/companies");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(err.response.data.message);
          } else {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    selectCategory(category) {
      for (let i in this.show) {
        this.show[i] = i === category;
      }
    },
    showLogoPopup() {
      this.$store.commit("SET_LOGO_POPUP", true);
    },
  },
  computed: {
    ...mapGetters({
      popupsInformation: "STATE_POPUP",
      companyThumbnail: "THUMBNAIL",
      imageIsLoaded: "LOADED_STATE",
    }),
  },
  watch: {
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.saveChanges();
          this.haveChanges = true;
          this.$router.push(newCount.to);
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$router.push(newCount.to);
        }
      },
    },
    companyThumbnail: {
      deep: true,
      handler(value) {
        if (this.companyInformation.logo !== value) this.saveChanges();
      },
    },
    companyInformation: {
      deep: true,
      handler(newCount) {
        this.haveChanges =
          JSON.stringify(this.informationBackups) === JSON.stringify(newCount);
      },
    },
  },
};
</script>

<style></style>
